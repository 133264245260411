<template>
<div class="d-center-content fill-height journey-detail" :class="{'mr-7': $vuetify.breakpoint.smAndDown}">
                    
    
        
        <v-row dense>
            <v-col cols="12" xl="7" lg="7" md="12" sm="12" xs="12"> 
                <div class="d-skew-mother" >
                    <div class="d-skew-left pr-5 pl-6" v-if="Jdetail.detail" >{{Jdetail.detail.title}}</div>
                    <div class="d-skew-center"></div>
                    <div class="d-skew-right"></div>
                    <div class="d-skew-abdecker "></div>
                    <div class="d-skew-abdecker-white "></div> 
                </div>
            </v-col>
            <v-col cols="12" xl="5" lg="5" md="12" sm="12" xs="12"> 
                <div  class="us-form-mother pmr-n8" :class="{'pt-3': $vuetify.breakpoint.mdAndDown}">
                    <v-pagination v-model="pages" :length="maxjourney" total-visible="7"  @next="setNextJourney()" @previous="setNextJourney()" @input="setNextJourney()"></v-pagination>
                </div>
            </v-col>
        </v-row>
        
        
   
    

    <div class="d-content-content" v-if="Jdetail.detail" style="width:inherit;">       
       
        <div class="title"> {{Jdetail.detail.subtitle}} </div>
        <div class="subtitle-2 pb-3"> {{Jdetail.detail.einleitung}} </div>

         <!-- <div>{{settheDate(Jdetail.dates[0].dates.date_from)}}</div> -->


        <div style="width:inherit;">
            <v-row dense >
                <v-col cols="12" xl="8" lg="8" md="7" sm="12" xs="12" class="pa-0 pl-1 pr-5" style="width:inherit;">                 
                    <div v-if="Jdetail.images[0]" style="width:inherit;height:400px;cursor:pointer;" class="journey-detail-pic" v-bind:style="{ 'background-image': 'url('+Jdetail.images[0].link+')' }"  @click="showSliderOverlay"></div>
                    <div v-else style="width:inherit;height:400px;cursor:pointer;" class="journey-detail-pic" v-bind:style="{ 'background-image': 'url('+notPic+')' }"></div>
                    <div style="overflow:hidden;height:137px;width:inherit;" class="mt-3 mb-5">
                        <div v-for="(slide,a) in Jdetail.images" :key="a" :src="slide.link" class="journey-detail-pic mr-2" style="display:inline-block;cursor:pointer;width:135px;height:135px;" @click="showSliderOverlay" v-bind:style="{ 'background-image': 'url('+slide.link+')'  }"> </div>
                    </div>
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="5" sm="12" xs="12">                     
                    <div style="width:inherit; display:flex;justify-content: center;">
                         <v-btn tile color="info" style="color:#272727" @click.stop="setmerkliste(Jdetail)"><fai :icon="['far', 'phone']" style="color:#272727;"></fai><span class="pl-1">Zur Merkliste</span></v-btn>
                        <v-btn tile color="info" style="min-width:36px;" class="pa-0 ma-0 mx-1" @click="openprint()"><fai  style="color:#272727" :icon="['far', 'print']" ></fai></v-btn>
                        <v-btn tile color="info" style="min-width:36px;" class="pa-0 ma-0"><a href="https://www.facebook.com/doering.reisen/" target="neu"><fai style="color:#272727" :icon="['far', 'thumbs-up']"></fai></a></v-btn> 
                    </div>                    
                    <div class="pr-1 pt-10" :class="{'pr-5': $vuetify.breakpoint.smAndDown}" style="width:inherit;">
                        <div style="width:100%;" class="primary--text font-weight-bold title">Termin</div>
                                                
                        <div v-if="Jdetail.dates[0].dates.duration==1" style="width:100%;" class="font-weight-bold pb-10">{{settheDate(Jdetail.dates[0].dates.date_from)}}</div>
                        <div v-else style="width:100%;" class="font-weight-bold pb-10">{{settheDate(Jdetail.dates[0].dates.date_from)}} - {{settheDate(Jdetail.dates[0].dates.date_till)}}</div>
                        
                        <div style="width:100%;color:#676767;">
                            <span style="text-align:left;">Dauer</span>

                            <span v-if="Jdetail.dates[0].dates.duration==1" style="float:right;">{{Jdetail.dates[0].dates.duration}} Tag</span>
                            <span v-else style="float:right;">{{Jdetail.dates[0].dates.duration}} Tage</span>
                        
                        </div>
                        <div style="width:100%;color:#676767;">
                            <span style="text-align:left;">Treuepunkte</span>
                            <span style="float:right;">{{Jdetail.detail.bonuspunkte}}</span>
                        </div>
                        <div style="width:100%;color:#676767;">
                            <span style="text-align:left;">Reisenummer</span>
                            <span style="float:right;">{{Jdetail.detail.code}}</span>
                        </div>
                        <div style="width:100%;" class="pt-10">
                            <span style="text-align:left;" class="primary--text font-weight-bold title">Preis</span>
                        </div>
                        <div style="width:100%;">
                            <span style="text-align:left;">pro Person</span>
                            <span style="float:right;"  class="primary--text font-weight-bold headline">{{ new Intl.NumberFormat('de-DE').format( Jdetail.dates[0].dates.prices[0].price / 1000000 ) }} ,- € </span>
                        </div>
                        <div style="width:inherit;" class="pt-10 mt-10">
                            <v-btn tile color="primary" block class="font-weight-bold headline mt-5" height="80" @click.stop="setJnameForm(Jdetail.detail.title, Jdetail.detail.code, settheDate(Jdetail.dates[0].dates.date_from) , settheDate(Jdetail.dates[0].dates.date_till))">Reise anfragen</v-btn>

                        </div>
                    </div>
                </v-col>
                <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                    <v-card class="pb-5 mb-5" v-if=" $vuetify.breakpoint.mdAndUp"> 
                        <v-tabs v-model="tab" background-color="info" light >
                            <v-tab active-class="primary" style="transform: skew(18deg);" class="px-6 ml-3 subtitle-1 font-weight-bold"><span style="transform: skew(-18deg);">Reiseverlauf</span></v-tab>
                            <v-tab active-class="primary" style="transform: skew(18deg);" class="px-6 subtitle-1 font-weight-bold"><span style="transform: skew(-18deg);">Leistungen</span></v-tab>
                            <v-tab active-class="primary" style="transform: skew(18deg);" class="px-6 subtitle-1 font-weight-bold"><span style="transform: skew(-18deg);">Hinweise</span></v-tab>
                            <v-tab active-class="primary" style="transform: skew(18deg);" class="px-6 subtitle-1 font-weight-bold"><span style="transform: skew(-18deg);">Preise</span></v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                            <v-tab-item> <v-card flat> <v-card-text> <div v-for="(item,a) in Jdetail.days" :key="a"> <div class="subtitle-1 font-weight-bold primary--text pt-5">{{item.title}}</div> <span>{{item.text}}</span> </div> </v-card-text> </v-card> </v-tab-item>
                            <v-tab-item> <v-card flat> <v-card-text v-html="Jdetail.detail.leistungen" class="subtitle-1 ml-5"></v-card-text> </v-card> </v-tab-item>
                            <v-tab-item> <v-card flat> <v-card-text v-html="Jdetail.detail.hinweise" class="subtitle-1 ml-5"></v-card-text> </v-card> </v-tab-item>
                            <v-tab-item>
                                <v-card flat>
                                    <v-card-text>
                                        <div v-for="(item,a) in Jdetail.dates" :key="a">
                                            <div class="subtitle-1 font-weight-bold primary--text pt-5">{{settheDate(item.dates.date_from)}} - {{settheDate(item.dates.date_till)}}</div>    
                                            <div class="subtitle-2" style="width:100%;" v-for="(price,b) in item.dates.prices" :key="b">
                                                <span style="text-align:left;">{{price.name}}</span>
                                                <span style="float:right;">{{ new Intl.NumberFormat('de-DE').format( price.price / 1000000 ) }} ,- € </span>
                                                <!-- <span style="float:right;">{{price.price / 1000000}} ,-€</span> -->
                                            </div>     
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>
                    <v-card class="mr-5" v-else> 
                       <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="subtitle-1 font-weight-bold">Reiseverlauf</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <div v-for="(item,a) in Jdetail.days" :key="a"> <div class="subtitle-1 font-weight-bold primary--text py-3" style="line-height:120%;">{{item.title}}</div> <span>{{item.text}}</span> </div>                                
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="subtitle-1 font-weight-bold">Leistungen</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <div v-html="Jdetail.detail.leistungen"></div>                             
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="subtitle-1 font-weight-bold">Hinweise</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <div v-html="Jdetail.detail.hinweise"></div>                             
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="subtitle-1 font-weight-bold">Preise</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <div v-for="(item,a) in Jdetail.dates" :key="a">
                                        <div class="subtitle-1 font-weight-bold primary--text">{{settheDate(item.dates.date_from)}} - {{settheDate(item.dates.date_till)}}</div>    
                                        <div class="subtitle-2" style="width:100%;" v-for="(price,b) in item.dates.prices" :key="b">
                                            <span style="text-align:left;">{{price.name}}</span>
                                            <span style="float:right;">{{ new Intl.NumberFormat('de-DE').format( price.price / 1000000 ) }} ,- € </span>
                                        </div>     
                                    </div>                            
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card>



                    
                </v-col>
            </v-row>
        </div>
        <!-- <pre> {{Jdetail}} </pre> -->
        <v-overlay :value="overlay" opacity="0.7" >           
            <fai :icon="['fa', 'times-circle']" style="font-size:40px;position:fixed;right:5%;top:5%;cursor:pointer;" @click="overlay = false" ></fai>           
            <v-carousel hide-delimiter-background>
                <v-carousel-item v-for="(item,i) in this.Jdetail.images" :key="i" :src="item.link" contain>
                    <div style="margin:auto; width:100%;text-align:center;opacity:0.5" v-if="item.picture">{{item.picture.copyright}}</div>
                </v-carousel-item>
            </v-carousel>
        </v-overlay>    


    </div>
  </div>
</template>

<script>    
    
    // import 'swiper/dist/css/swiper.css'
    // import { swiper, swiperSlide } from 'vue-awesome-swiper'
    
    import { mapState } from "vuex"
    import notPic from "@/assets/images/notPic.jpg"
    import moment from 'moment'

export default {
    data() {
        return {             
            tab: null,
            
            // slider: [ 
            //     { src: 'https://lww.de/bilder/bigpic/cover_wika1.jpg'}, 
            //     { src: 'https://lww.de/images/reiseb.jpg'}, 
            //     { src: 'https://lit.unisigns.de/Bilder_Druck/xxl/0000005416.jpg'},             
            // ],
            notPic,
            core,   
            absolute: true,
            overlay:false,               
            Jdetail: {},
            loggedIn: false,
            pages: 1,
            tempItem:null,
            maxjourney:null,      
            oldPath:null,
            newMerkItem:[] 
        }
    },    
    // components: {
    //     swiper,
    //     swiperSlide
    // },    
    watch: {
        $route(to, from) {
            this.getJourney();
            // setTimeout(() => {
            //     this.loadswiper();
            // },500);
        }

    },   
    computed: {
        ...mapState(["WebsiteStore", "JourneyStore", "JourneyPanz", "Merkliste"]),
        journeyCategories() {
            return this.Jdetail.categories
                .reduce((acc, cur) => {
                    acc.push(cur.category.name)
                    return acc
                }, [])
                .join(", ")
        },
        journeyCountries() {
            return this.Jdetail.countries
                .reduce((acc, cur) => {
                    acc.push(cur.country.name)
                    return acc
                }, [])
                .join(", ")
        },
   
        journeyAbPreis() {
            try {
                let price = this.Jdetail.dates[0].dates.prices[0].price
                this.Jdetail.dates.forEach(date => {
                    date.dates.prices.forEach(p => {
                        price = Math.min(price, p.price)
                    })
                })
                return new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(price / 1000000)
            } catch (e) {
                try {
                    return this.Jdetail.dates[0].dates.prices[0].price
                } catch (error) {
                    return ""
                }
            }
        },
        journeyDays() {
            try {
                try {
                    return this.Jdetail.extras.find(ext => ext.type == "Reisedauer").text.split(/\s*\/\s*/)[0].trim();
                } catch (error) {
                    return this.Jdetail.dates[0].dates.duration + (this.Jdetail.dates[0].dates.duration == 1 ? " Tag" : " Tage")
                }
            } catch (e) {
                return ""
            }
        },
        journeyDuration() {
            try {
                return this.Jdetail.extras.find(ext => ext.type == "Reisedauer").text
            } catch (e) {
                return this.journeyDays
            }
        },        

        usJourneyDetailSlider() {
            return this.$refs.usJourneySlider.swiper
        }
  
  
    }, 
    mounted() {
           
        
        if (localStorage.getItem("credentials") !== null) {
            this.loggedIn = true;
        }       
        
        setTimeout(() => {
            for (var a=0; a<this.JourneyPanz.length; a++){                    
                if( this.Jdetail.id_jrn_journey == this.JourneyPanz[a].id )  {                        
                    this.tempItem = this.JourneyPanz[a];
                    this.JourneyPanz.splice(a , 1);
                    a = this.JourneyPanz.length;     
                }
            }                
            this.JourneyPanz.unshift(this.tempItem);
            this.oldPath = this.JourneyPanz[0].path;
            this.maxjourney = this.JourneyPanz.length;

           
        
        },500);
        this.getJourney();            
      
    },
    methods: { 
        setmerkliste(journey){          
            this.newMerkItem = [];
            var array =  JSON.parse(localStorage.getItem("merkliste"));            
            for (var a=0; a< array.length; a++) {
                this.newMerkItem.push(array[a]);
            }         
            this.newMerkItem.push(journey);
            localStorage.setItem("merkliste", JSON.stringify(this.newMerkItem) );
    
        },
        
        setJnameForm(jn,jcode, jdatefrom, jdateto){
            this.$store.commit('setJourneyName', jn);
            this.$store.commit('setJourneyCode', jcode);
            this.$store.commit('setJourneyDatefrom',  jdatefrom);
            this.$store.commit('setJourneyDateto', jdateto);
            
            this.navigateJourney('/forms/AnfrageJourney') 
        },     
        openprint(){
            window.print();

        },
        settheDate(date){ return  moment(String(date)).locale("de").format('ddd, DD.MM.YYYY'); },   
        showSliderOverlay(){
            this.overlay = true;
        },      
        navigateJourney(nextPath) {              
            if(nextPath == this.oldPath) {return;}  
            this.oldPath = nextPath;  
            core.navigate({ path: nextPath })
        },
        setNextJourney(){
            this.navigateJourney(this.JourneyPanz[this.pages-1].path)     
        },        
        showSliderOverlay(){
            this.overlay = true;
        },
        getJourney() {
            let args = core.getPathArgs(["id"])
            core.backend("jrn.getJourneyWeb", { id_jrn_journey: args.id }, (err, res) => {
                
                this.Jdetail = JSON.parse(JSON.stringify(res.data))
                
                if (this.Jdetail.detail.leistungen != "") {
                    this.Jdetail.detail.leistungen = ("<ol><li class='us-journey-list'>" + JSON.parse(JSON.stringify(this.Jdetail.detail.leistungen.replace(/\n/g, "</li><li class='us-journey-list'>")))+'</li></ol>').replace(/<li class='us-journey-list'>\s*<\/li>/,'')
                }

                // if (this.Jdetail.detail.zubuchbareleistungen != "" && this.Jdetail.detail.zubuchbareleistungen != null && this.Jdetail.detail.zubuchbareleistungen != undefined) {
                //     this.Jdetail.detail.zubuchbareleistungen = ("<li class='us-journey-list'>" + JSON.parse(JSON.stringify(this.Jdetail.detail.zubuchbareleistungen.replace(/\n/g, "</li><li class='us-journey-list'>")))+'</li>').replace(/<li class='us-journey-list'>\s*<\/li>/,'')
                // }
                // if (this.Jdetail.detail.highlights != "") {
                //     this.Jdetail.detail.highlights = "<li>" + JSON.parse(JSON.stringify(this.Jdetail.detail.highlights.replace(/\n/g, "</li><li>")))
                // }
                if (this.Jdetail.days.length === 1) {
                    if (this.Jdetail.days[0].title == "1. Tag" && this.Jdetail.days[0].text == "") {
                        this.Jdetail.days = []
                    }
                }

               
            })
        },
        showJourneyDetailbackgroundPic(image) {
            console.log(image);
            if (image == undefined) {
                image = {
                    link: this.notPic
                }
            }
            return image.link
        },
    
   
    }

}
</script>

<style lang="scss">
   
   .us-form-mother .v-pagination {
       transform:scale(0.8);
       
   }
    .journey-detail-pic{
        background-size: cover; 
        background-position: center;
    }
    .journey-detail .v-tabs-slider {
        display:none !important;
    }
    .journey-detail .v-window { &-x-transition, &-x-reverse-transition, &-y-transition, &-y-reverse-transition { &-enter-active, &-leave-active { transition: 1.6s cubic-bezier(.25, .8, .50, 1) !important; } } }
    // .swiper-slide.slide, .gallery-thumbs .slide{ background-size: cover; background-position: center; }
    // .gallery-top { height: 75%!important; padding-bottom:10px; }
    // .gallery-thumbs { height: 25%!important; box-sizing: border-box; background-color: #ffffff; }
    // .gallery-thumbs .swiper-slide { opacity: 0.4; }
    // .gallery-thumbs .swiper-slide-active { opacity: 1; }

    .us-journey-list{        
        list-style:disc;
        margin-left:15px;
    }

</style>

    